<template>
  <div>
    <v-facebook-login-scope
      :login-options="optionsEscope"
      @sdk-init="setScope"
      :app-id="getIdApp"
    >
      <button
        :id="getBtnId"
        :class="custonClass"
        class="btn w-100"
        slot-scope="scope"
        @click="login"
        :disabled="isLoged"
      >
        <img :src="require('@/assets/icons/facebook.svg')" alt="Facebook" />
        <span style="
    color: white;
">{{ getTitleBtn }}</span>
      </button>
    </v-facebook-login-scope>
  </div>
</template>
<script>
import { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component";
import {mapMutations} from 'vuex'

export default {
  name: "LoginFacebook",
  props: {
    isLoged: {
      type: Boolean,
      required: true,
    },
    custonClass: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  components: {
    VFacebookLoginScope,
  },
  data: () => ({
    optionsEscope: {
      scope: `ads_management, 
        pages_read_engagement,
        pages_show_list,
        business_management,
        pages_manage_metadata, 
        leads_retrieval,
        pages_manage_ads, 
        email,
        public_profile`,
    },
    fb: null,
    uriImg: '/picture?height=45&width=45&redirect=false',
    uriAccount:'/accounts/?fields=name,access_token,picture&limit=1000&access_token='
  }),
  computed: {
    getIdApp: () => process.env.FACEBOOK_APP_ID,
    getBtnId() {
      return this.isLoged ? "btn-facebook-loged" : "btn-facebook";
    },
    getTitleBtn() {
      if(this.title){
        return this.title;

      }else if(this.isLoged){
        return "Facebook conectado"

      }else {
        return "Entrar com o Facebook"

      }
    },
  },
  methods: {
    ...mapMutations(['loading']),
    
    setScope(response) {
      this.fb = response.scope;
    },
    async login() {
      this.loading(true)
      const dataLogin = await this.fb.login();
      if (dataLogin.status === "connected") {
        await FB.api(dataLogin.authResponse.userID+this.uriAccount+dataLogin.authResponse.accessToken, 
        async (response) => {
          this.loading(false)
          this.$emit("loged", response);
        });
      }
      this.loading(false)
    },
  },
};
</script>
<style scoped>
img {
  margin-right: 20.75px;
}

#btn-facebook {
  background-color: #3b37ff;
}

#btn-facebook-loged {
  background-color: #00af7b;
}

#btn-facebook-loged,
#btn-facebook {
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  letter-spacing: 0.2px;
  margin-bottom: 7.52px;
  display: flex;
  justify-content: center;
  color: #ffffff;
  width: 336px;
  height: 50px;
}
</style>